@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.hexacat {
    font-family: 'Courgette', cursive;
}

.description {
    font-family: 'Roboto', sans-serif;
}