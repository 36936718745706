@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.font-roboto {
    font-family: 'Roboto', sans-serif;
}

* {
    font-family: 'Roboto', sans-serif;
}