@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

.hexacat {
    font-family: 'Courgette', cursive;
    animation: logoAnimation 2s ease-in-out infinite;
}

@keyframes logoAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}